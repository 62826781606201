import { useEffect, useRef, useState } from 'react'
import './App.css'
import Loader from './components/Loader'
import MyLookScene from './components/MyLookScene'

const App = () => {

  const [step, setStep] = useState(1)
  const [showCamera, setShowCamera] = useState(false)
  const [hasCamera, setHasCamera] = useState(false)
  const items = useRef([])
  const videoStream = useRef(false)
  const capturedImage = useRef(false)
  const logoRef = useRef(false)
  const startContainerRef = useRef(false)
  const cameraRef = useRef(false)
  const sceneRef = useRef(false)
  const [loading, setLoading] = useState(true)
  const [streamLoading, setStreamLoading] = useState(false)

  // Let's detect if the user has a camera
  useEffect(() => {
    const hasUserMedia = !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
    setHasCamera(hasUserMedia)
    fetchItems()
    setTimeout(animateEntry, 500)
  }, [])

  // Animate elements in 
  const animateEntry = () => {
    setLoading(false)

    // Logo
    animateElIn(logoRef.current, 100)

    // Container
    animateElIn(startContainerRef.current, 150)
  }

  const animateElIn = (el, delay) => {
    setTimeout(() => {
      el.classList.remove("exit")
      setTimeout(() => {
        el.classList.remove("animate")
      }, delay + 100)
    }, delay)
  }

  const animateElOut = (el, delay) => {
    setTimeout(() => {
      el.classList.add("animate")
      setTimeout(() => {
        el.classList.remove("exit")
      }, delay + 100)
    }, delay)
  }

  const handleShowCamera = () => {
    setShowCamera(true)
    setStreamLoading(true)

    // Start stream
    setTimeout(() => {

      const videoEl = document.getElementById("input-video")

      navigator.mediaDevices.getUserMedia({
        video: {
          aspectRatio: 16/9
        },
        audio: false
      }).then((stream) => {
        videoStream.current = stream
        videoEl.srcObject = videoStream.current
        setStreamLoading(false)

        // Animate in
        animateElIn(cameraRef.current, 100)
      });
    })
  }

  const handleCancelCamera = () => {
    handleStopVideoStream()
    setShowCamera(false)
  }

  const handleStopVideoStream = () => {
    const videoEl = document.getElementById("input-video")

    if (videoStream.current) {
      videoStream.current.getTracks().forEach((track) => {
        track.stop()
      })

      videoStream.current = false
      videoEl.srcObject = null
    }
  }

  const handleCaptureImage = () => {
    const videoEl = document.getElementById("input-video")

    if (!videoEl || !videoStream.current) {
      return
    }

    const streamSettings = videoStream.current.getVideoTracks()[0].getSettings()
    const height = streamSettings.height
    const width = streamSettings.width

    const canvas = document.createElement("canvas")
    canvas.width = width
    canvas.height = height
    canvas.getContext("2d").drawImage(videoEl, 0, 0)
    capturedImage.current = canvas.toDataURL("image/jpeg")
    handleCancelCamera()
    setStep(2)
    setTimeout(() => animateElIn(sceneRef.current))
  }

  const handleCloseScene = () => {
    animateElOut(sceneRef.current)
    
    setTimeout(() => {
      setStep(1)
      capturedImage.current = null
      setTimeout(animateEntry, 100)
    }, 300)
  }

  const fetchItems = async () => {

    try {
      // Below, we were fetching from the API... no real need to do that at this point, using a static export
      // const apiUrl = "https://portal.threedy.ai/api/full_jobs/list_via_put/?offset=0&limit=50&ordering=-job_id"
      // const apiUrl = "https://portal-test.threedy.ai/api/full_jobs/list_via_put/?offset=0&limit=50&ordering=-job_id"
      // const params = {
      //   cli: ["74"],
      //   group: []
      // }

      const res = await fetch("./jobs.json", {
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         }
      }).then((res) => res.json())

      const filtered = res.filter((item) => {
        return item.source_image_url && item.models && item.models.glb
      })

      items.current = filtered

    }
    catch (e) {
      console.log("Error fetching items: ", e)
    }
  };

  return (
    <div className={step === 2 ? "my-look-container inverted" : "my-look-container"}>
      <img src="/adler-logo.webp" className="logo exit animate" alt="adler logo" ref={logoRef} />

      {/* Loader */}
      { loading && <Loader /> }

      {/* Gather image */}
      { step === 1 &&
        <div className="my-look-start exit animate" ref={startContainerRef}>
          <h1>Welcome</h1>
          <h2>Let's perfect your look</h2>
          <button className="gradient-btn" onClick={handleShowCamera} disabled={!hasCamera}>Get Started</button>
        </div>
      }

      {/* Display scene */}
      { step === 2 &&
        <MyLookScene image={capturedImage.current} onBack={handleCloseScene} items={items.current} ref={sceneRef} />
      }

      {/* Camera */}
      {
        showCamera &&
          <div className="my-look-camera-container exit animate" ref={cameraRef}>
            <div className="my-look-camera">
              <p>Instructions on aligning face with guide go here</p>
              <div className="input-video-container">
                <img src="/face-mask.png" alt="guide" />
                <div className="input-video-loader"><h2>Loading...</h2></div>
                <video id="input-video" autoPlay={true} muted={true} playsInline={true}/>
              </div>
              <div className="input-video-btn-group">
                <button disabled={streamLoading} className="save-btn gradient-btn" onClick={handleCaptureImage}>Continue</button>
                <button className="cancel-btn" onClick={handleCancelCamera}>Cancel</button>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

export default App
