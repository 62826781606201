import "./MyLookScene.css"
import { useEffect, useRef, useState, forwardRef } from "react"
import '@babylonjs/core/Rendering/outlineRenderer'
import { Engine, Scene, FreeCamera, PointerEventTypes, AssetsManager, HemisphericLight, MeshBuilder, Vector3, Color3, DynamicTexture, StandardMaterial } from "@babylonjs/core"
import "@babylonjs/loaders"
import Loader from "./Loader"

const MyLookScene = forwardRef((props, ref) => {

  const capturedImage = useRef(props.image)
  const manager = useRef(null)
  const [addedMeshes, setAddedMeshes] = useState([])
  const [loading, setLoading] = useState(false)
  const _scene = useRef(null)
  const _defaultScale = 40

  useEffect(() => {
    setTimeout(setupScene)
  }, []) // eslint-disable-line

  const setupScene = () => {

    const canvas = document.getElementById("renderCanvas")
    if (!canvas) {
      return
    }

    const engine = new Engine(canvas, true, {preserveDrawingBuffer: true})
    const scene = new Scene(engine)
    _scene.current = scene
    scene.clearColor = new Color3(1, 0.95, 0.99)

    const camera = new FreeCamera("camera", new Vector3(0, 0, -10), scene)
    camera.setTarget(Vector3.Zero())
    // camera.attachControl()
    const light = new HemisphericLight("light", new Vector3(0, 1, 10), scene)

    const tex = new DynamicTexture('dt', {
      width: 640,
      height: 360
    }, scene)

    const mat = new StandardMaterial('dm', scene)
    const ctx = tex.getContext()
    mat.emissiveTexture = tex

    let img = new Image()
		img.onload = function() {
			ctx.drawImage(img, 0, 0)
			tex.update()
		}

    img.src = capturedImage.current

    // Create "mirror"
    const mirror = MeshBuilder.CreatePlane("mirror")
    mirror.scaling = new Vector3(15, 10, 10)
    mirror.position.y = 0
    mirror.position.x = 0
    mirror.material = mat

    // Setup assets manager
    manager.current = new AssetsManager(scene)
    manager.current.useDefaultLoadingScreen = false
    
    let currentMesh, startingPoint
    const getGroundPosition = () => {
      const pickinfo = scene.pick(scene.pointerX, scene.pointerY, (mesh) => {
        return mesh === mirror
      })

      if (pickinfo.hit) {
        return pickinfo.pickedPoint
      }

      return null
    }

    const pointerDown = (mesh) => {
      // Find root mesh
      currentMesh = findAbsoluteParent(mesh)
      startingPoint = getGroundPosition()
    }

    const findAbsoluteParent = (currentMesh) => {
      if (currentMesh._parentNode !== null) {
        return findAbsoluteParent(currentMesh._parentNode)
      }

      return currentMesh
    }

    const pointerUp = () => {
      if (startingPoint) {
        startingPoint = null
        return
      }
    }

    const pointerMove = () => {
      if (!startingPoint) {
        return
      }

      const current = getGroundPosition()
      if (!current) {
        return
      }

      const diff = current.subtract(startingPoint)

      // Massage the diff a bit
      diff.x = diff.x * 0.5
      diff.y = diff.y * 0.5
      currentMesh.position.addInPlace(diff)
      startingPoint = current
    }

    scene.onPointerObservable.add((pointerInfo) => {      		
      switch (pointerInfo.type) {
        default: {
          break
        }
        case PointerEventTypes.POINTERDOWN:
          if (pointerInfo.pickInfo.hit && pointerInfo.pickInfo.pickedMesh !== mirror) {
            pointerDown(pointerInfo.pickInfo.pickedMesh)
          }
          break
        case PointerEventTypes.POINTERUP:
          pointerUp()
          break
        case PointerEventTypes.POINTERMOVE:     
          pointerMove()
          break
        }
      }
    );

    engine.runRenderLoop(() => {
      scene.render()
    })
  }

  const getPathAndFile = (path) => {
    const split = path.split("/")
    const file = split[split.length-1]

    return {
      file: file,
      path: path.replace(file, "")
    }
  }

  const handleAddItem = (item) => {
    console.log("add item to scene: ", item)
    if (!manager.current) {
      return
    }

    setLoading(true)
    const params = getPathAndFile(item.models.glb.url)
    console.log(params)
    const task = manager.current.addMeshTask(item.job_id, "", params.path, params.file);

    task.onSuccess = (task) => {
      console.log("Item loaded...")
      const uid = item.job_id + new Date().valueOf().toString()
      task.loadedMeshes[0].scaling = new Vector3(_defaultScale, _defaultScale, _defaultScale)
      task.loadedMeshes[0].id = task.loadedMeshes[0].name = uid
      task.loadedMeshes[0]._position.x = task.loadedMeshes[0]._position.y = -3

      setAddedMeshes([...addedMeshes, {
        uid: uid,
        job: item,
        scale: _defaultScale
      }])

      setLoading(false)
    }

    task.onError = function (task, message, exception) {
      console.log(message, exception);
      setLoading(false)
    }

    manager.current.load()
  }

  const handleBack = () => {
    if (_scene.current) {
      _scene.current.dispose()
      _scene.current = null
    }

    props.onBack()
  }

  const handleReset = () => {
    if (addedMeshes.length > 0 && _scene.current) {
      addedMeshes.forEach((meshObj) => {
        const mesh = findMeshInScene(meshObj.uid)
        if (mesh) {
          mesh.dispose()
        }
      })

      setAddedMeshes([])
    }
  }

  const findMeshInScene = (meshId) => {
    if (_scene.current) {
      for (let i = 0; i < _scene.current.meshes.length; i++) {
        if (_scene.current.meshes[i].id === meshId) {
          return _scene.current.meshes[i]
        }
      }
    }

    return false
  }

  const handleRemoveItem = (uid) => {
    const mesh = findMeshInScene(uid)
    if (mesh) {
      mesh.dispose()
    }

    setAddedMeshes([...addedMeshes].filter((item) => item.uid !== uid))
  }

  const handleMeshScale = (uid, scale) => {
    const mesh = findMeshInScene(uid)
    if (mesh) {
      mesh.scaling = new Vector3(scale, scale, scale)
      setAddedMeshes([...addedMeshes].map((item) => {
        if (item.uid === uid) {
          return {...item, scale: scale}
        }

        return item
      }))
    }
  }
  
  const handleExport = () => {
    const renderCanvas = document.getElementById("renderCanvas")
    if (!renderCanvas) {
      return
    }

    const dataUrl = renderCanvas.toDataURL("image/jpeg")
    const link = document.createElement("a")
    link.href = dataUrl
    link.download = "My-Look-Photo-" + new Date().valueOf().toString() + ".jpg"
    link.click()
    link.remove()
  }

  return (
    <div className="my-look-scene exit animate" ref={ref}>
      { loading && <Loader /> }
      <div className="my-look-header">
        <button onClick={handleBack} className="back-btn"><span className="icon-chevron-left"></span></button>
        <button onClick={handleReset}><span className="icon-refresh-cw"></span></button>
        <button onClick={handleExport} className="gradient-btn"><span className="icon-download"></span></button>
      </div>
      <div className="my-look-canvas">
        <canvas id="renderCanvas" />
      </div>
      <div className="my-look-items-container">
        {
          addedMeshes.length > 0 &&
          <div className="my-look-items added">
            <h3>Added Items</h3>
            <ul className="my-look-items-list">
              {
                addedMeshes.map((meshObj) => {
                  const item = meshObj.job
                  return (
                    <li key={meshObj.uid}>
                      <button className="remove-btn" onClick={() => handleRemoveItem(meshObj.uid)}><span className="icon-x"></span></button>
                      <div className="img-container"><div className="img" style={{backgroundImage: `url(${item.source_image_url})`}}></div></div>
                      <h4>{item.title}</h4>
                      <h5>$50 USD</h5>
                      <div className="scale-container">
                        <input type="range" min="5" max="200" value={meshObj.scale} onChange={(e) => handleMeshScale(meshObj.uid, e.target.value)} className="slider" />
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        }
        <div className="my-look-items">
          <h3>Items</h3>
          <ul className="my-look-items-list">
            {
              props.items.map((item) => {
                return (
                  <li key={item.job_id} onClick={() => handleAddItem(item)}>
                    <div className="img-container"><div className="img" style={{backgroundImage: `url(${item.source_image_url})`}}></div></div>
                    <h4>{item.title}</h4>
                    <h5>$50 USD</h5>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )
})

export default MyLookScene